/**
 * datenschutz.jsx
 * 
 * @file Privacy policy page of the website.
 * @author Robin Walter <hello@robinwalter.me>
 */

import clsx from 'clsx'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

// internal imports
import { Layout } from '../components/Layout'
import { NoSSR } from '../components/NoSSR'
import { Obfuscate } from '../components/Obfuscate'

/**
 * This Component represents the privacy policy page of this application.
 *
 * @returns {Node} The rendered page.
 */
const DatenschutzPage = () => {

    const data = useStaticQuery( graphql`
        query DatenschutzQuery {
            site {
                siteMetadata {
                    author
                    email
                    menuLinks {
                        link
                    }
                    siteUrl
                }
            }
        }
    ` )

    return (
        <>
            <GatsbySeo title="Datenschutzerklärung" />
            <Layout>

                <div
                    className={ clsx( 'uk-section-muted', 'uk-section', 'uk-section-large' ) }
                    id="datenschutz">
                    <div className={ clsx( 'uk-container' ) }>

                        <h1>Datenschutzerklärung</h1>

                        <h2>Einleitung</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Mit der folgenden Datenschutzerklärung möchte ich Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) ich zu welchen Zwecken und in welchem Umfang verarbeite. Die Datenschutzerklärung gilt für alle von mir durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung meiner Leistungen als auch insbesondere auf meinen Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. meiner Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“).
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                        <p className={ clsx( 'uk-text-justify' ) }>Stand: 8. Mai 2020</p>
                        
                        <h2>Inhaltsübersicht</h2>
                        <ul className={ clsx( 'index', 'uk-list', 'uk-list-divider' ) }>
                            <li><a className="index-link" href="#m14" uk-scroll="">Einleitung</a></li>
                            <li><a className="index-link" href="#m3" uk-scroll="">Verantwortlicher</a></li>
                            <li><a className="index-link" href="#mOverview" uk-scroll="">Übersicht der Verarbeitungen</a></li>
                            <li><a className="index-link" href="#m13" uk-scroll="">Maßgebliche Rechtsgrundlagen</a></li>
                            <li><a className="index-link" href="#m27" uk-scroll="">Sicherheitsmaßnahmen</a></li>
                            <li><a className="index-link" href="#m134" uk-scroll="">Einsatz von Cookies</a></li>
                            <li><a className="index-link" href="#m225" uk-scroll="">Bereitstellung des Onlineangebotes und Webhosting</a></li>
                            <li><a className="index-link" href="#m182" uk-scroll="">Kontaktaufnahme</a></li>
                            <li><a className="index-link" href="#m263" uk-scroll="">Webanalyse und Optimierung</a></li>
                            <li><a className="index-link" href="#m136" uk-scroll="">Präsenzen in sozialen Netzwerken</a></li>
                            <li><a className="index-link" href="#m328" uk-scroll="">Plugins und eingebettete Funktionen sowie Inhalte</a></li>
                            <li><a className="index-link" href="#m12" uk-scroll="">Löschung von Daten</a></li>
                            <li><a className="index-link" href="#m15" uk-scroll="">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
                            <li><a className="index-link" href="#m10" uk-scroll="">Rechte der betroffenen Personen</a></li>
                            <li><a className="index-link" href="#m42" uk-scroll="">Begriffsdefinitionen</a></li>
                        </ul>
                        
                        <h2 id="m3">Verantwortlicher</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Robin Walter<br />
                            Heilkenstraße 30<br />
                            58300 Wetter (Ruhr)<br />
                            Deutschland
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>E-Mail-Adresse</strong>: <NoSSR><Obfuscate email={ data.site.siteMetadata.email } /></NoSSR></p>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Impressum</strong>: <Link to={ data.site.siteMetadata.menuLinks[ 3 ].link }>{ `${ data.site.siteMetadata.siteUrl }${ data.site.siteMetadata.menuLinks[ 3 ].link }` }</Link></p>

                        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.</p>
                        <h3>Arten der verarbeiteten Daten</h3>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
                            <li>Inhaltsdaten  (z.B. Texteingaben, Fotografien, Videos).</li>
                            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
                            <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                            <li>Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
                        </ul>
                        <h3>Kategorien betroffener Personen</h3>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li>Kommunikationspartner.</li>
                            <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                        </ul>
                        <h3>Zwecke der Verarbeitung</h3>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                            <li>Besuchsaktionsauswertung.</li>
                            <li>Kontaktanfragen und Kommunikation.</li>
                            <li>Profiling (Erstellen von Nutzerprofilen).</li>
                            <li>Remarketing.</li>
                            <li>Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</li>
                            <li>Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).</li>
                            <li>Vertragliche Leistungen und Service.</li>
                        </ul>
                        <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
                        </p>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                            </li>
                            <li>
                                <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
                            </li>
                            <li>
                                <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                            </li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
                        </p>

                        <h2 id="m27">Sicherheitsmaßnahmen</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Kürzung der IP-Adresse</strong>: Sofern es uns möglich ist oder eine Speicherung der IP-Adresse nicht erforderlich ist, kürzen wir oder lassen Ihre IP-Adresse kürzen. Im Fall der Kürzung der IP-Adresse, auch als "IP-Masking" bezeichnet, wird das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht (die IP-Adresse ist in diesem Kontext eine einem Internetanschluss durch den Online-Zugangs-Provider individuell zugeordnete Kennung). Mit der Kürzung der IP-Adresse soll die Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder wesentlich erschwert werden.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
                        </p>

                        <h2 id="m134">Einsatz von Cookies</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong></p>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen hat.</li>
                            <li>
                                <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des Browsers gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie gespeichert werden.
                            </li>
                            <li><strong>First-Party-Cookies:</strong> First-Party-Cookies werden von uns selbst gesetzt.</li>
                            <li><strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.</li>
                            <li>
                                <strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong> Cookies können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
                            </li>
                            <li>
                                <strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>: Ferner werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. . Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.
                            </li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall des Trackings, über die Webseiten <a href="https://optout.aboutads.info" rel="noopener noreferrer nofollow" target="_blank">https://optout.aboutads.info</a> und <a href="https://www.youronlinechoices.com/" rel="noopener noreferrer nofollow" target="_blank">https://www.youronlinechoices.com/</a> erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung</strong>: Bevor wir Daten im Rahmen der Nutzung von Cookies verarbeiten oder verarbeiten lassen, bitten wir die Nutzer um eine jederzeit widerrufbare Einwilligung. Bevor die Einwilligung nicht ausgesprochen wurde, werden allenfalls Cookies eingesetzt, die für den Betrieb unseres Onlineangebotes erforderlich sind. Deren Einsatz erfolgt auf der Grundlage unseres Interesses und des Interesses der Nutzer an der erwarteten Funktionsfähigkeit unseres Onlineangebotes.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Cookie-Einstellungen/ -Widerspruchsmöglichkeit</strong>:</p>
                        <p className={ clsx( 'uk-text-justify' ) }><NoSSR><button className={ clsx( 'uk-button', 'uk-button-primary', 'uk-button-small' ) } type="button" uk-toggle={ JSON.stringify( { target: '#cookie-banner' } ) }>Öffne Cookie-Hinweis</button></NoSSR></p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                            <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        
                        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere Informationen betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.
                        </p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Verarbeitete Datenarten:</strong> Inhaltsdaten  (z.B. Texteingaben, Fotografien, Videos), Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                            <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service.</li>
                            <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>1&amp;1 IONOS:</strong> Hostingplattform für E-Commerce / Websites; Dienstanbieter: 1&amp;1 IONOS SE, Elgendorfer Str. 57, 56410 Montabaur, Deutschland; Website: <a href="https://www.ionos.de" rel="noopener noreferrer nofollow" target="_blank">https://www.ionos.de</a>; Datenschutzerklärung: <a href="https://www.ionos.de/terms-gtc/terms-privacy" rel="noopener noreferrer nofollow" target="_blank">https://www.ionos.de/terms-gtc/terms-privacy</a>.
                            </li>
                        </ul>
                        
                        <h2 id="m182">Kontaktaufnahme</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen.
                        </p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten  (z.B. Texteingaben, Fotografien, Videos).</li>
                            <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                            <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.</li>
                            <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        
                        <h2 id="m263">Webanalyse und Optimierung</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung bedürfen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile zu testen und optimieren.</p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren mit dem gleichen Zweck genutzt werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte Webseiten und dort genutzte Elemente und technische Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können je nach Anbieter auch diese verarbeitet werden.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Einstellungen/Widerspruchsmöglichkeit</strong>:</p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <NoSSR>
                                <iframe
                                    className={ clsx( 'uk-height-small', 'uk-width-1-1' ) }
                                    src={ `https://stats.robinwalter.me/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=${ 'F6F6F6' }&fontColor=${ '26252D' }&fontSize=18px&fontFamily=${ 'Montserrat%20Alternates' }` }
                                    style={ { border: 0 } } />
                            </NoSSR>
                        </p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                            <li>
                                <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Besuchsaktionsauswertung, Profiling (Erstellen von Nutzerprofilen).
                            </li>
                            <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
                            <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Matomo:</strong> Die durch das Cookie erzeugte Informationen über Ihre Benutzung dieser Webseite werden nur auf unserem Server gespeichert und nicht an Dritte weitergegeben; Dienstanbieter: Webanalyse/ Reichweitenmessung im Selbsthosting; Löschung von Daten: Die Cookies haben eine Speicherdauer von maximal 13 Monaten; Widerspruchsmöglichkeit (Opt-Out): Nutzer können der Verarbeitung ihrer Daten durch Matomo jederzeit mit Wirkung für die Zukunft widersprechen. In diesem Fall wird in ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten mehr erhebt. Wenn Nutzer ihre Cookies löschen, so hat dies jedoch zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und daher von den Nutzern erneut aktiviert werden muss...
                            </li>
                        </ul>
                        
                        <h2 id="m136">Präsenzen in sozialen Netzwerken</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.</p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter, die unter dem Privacy-Shield zertifiziert sind oder vergleichbare Garantien eines sicheren Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU einzuhalten.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.</p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
                        </p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten  (z.B. Texteingaben, Fotografien, Videos), Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                            </li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                            <li>
                                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing, Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
                            </li>
                            <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA; Website: <a href="https://www.instagram.com" rel="noopener noreferrer nofollow" target="_blank">https://www.instagram.com</a>; Datenschutzerklärung: <a href="https://instagram.com/about/legal/privacy" rel="noopener noreferrer nofollow" target="_blank">https://instagram.com/about/legal/privacy</a>.
                            </li>
                            <li>
                                <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: <a href="https://www.facebook.com" rel="noopener noreferrer nofollow" target="_blank">https://www.facebook.com</a>; Datenschutzerklärung: <a href="https://www.facebook.com/about/privacy" rel="noopener noreferrer nofollow" target="_blank">https://www.facebook.com/about/privacy</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA): <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" rel="noopener noreferrer nofollow" target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen: <a href="https://www.facebook.com/settings?tab=ads" rel="noopener noreferrer nofollow" target="_blank">https://www.facebook.com/settings?tab=ads</a>; Zusätzliche Hinweise zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten auf Facebook-Seiten: <a href="https://www.facebook.com/legal/terms/page_controller_addendum" rel="noopener noreferrer nofollow" target="_blank">https://www.facebook.com/legal/terms/page_controller_addendum</a>, Datenschutzhinweise für Facebook-Seiten: <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" rel="noopener noreferrer nofollow" target="_blank">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>.
                            </li>
                            <li>
                                <strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland; Website: <a href="https://www.linkedin.com" rel="noopener noreferrer nofollow" target="_blank">https://www.linkedin.com</a>; Datenschutzerklärung: <a href="https://www.linkedin.com/legal/privacy-policy" rel="noopener noreferrer nofollow" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA): <a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active" rel="noopener noreferrer nofollow" target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active</a>; Widerspruchsmöglichkeit (Opt-Out): <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" rel="noopener noreferrer nofollow" target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
                            </li>
                            <li>
                                <strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA; Datenschutzerklärung: <a href="https://twitter.com/de/privacy" rel="noopener noreferrer nofollow" target="_blank">https://twitter.com/de/privacy</a>, (Einstellungen) <a href="https://twitter.com/personalization" rel="noopener noreferrer nofollow" target="_blank">https://twitter.com/personalization</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA): <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active" rel="noopener noreferrer nofollow" target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a>.
                            </li>
                        </ul>
                    
                        <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
                        </p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten  (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                            <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                            <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit, Vertragliche Leistungen und Service.</li>
                            <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                        </ul>
                        <p className={ clsx( 'uk-text-justify' ) }><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                        <ul className={ clsx( 'm-elements', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Font Awesome:</strong> Darstellung von Schriftarten und Symbolen; Dienstanbieter:  Fonticons, Inc. ,6 Porter Road Apartment 3R, Cambridge, MA 02140, USA; Website: <a href="https://fontawesome.com/" rel="noopener noreferrer nofollow" target="_blank">https://fontawesome.com/</a>; Datenschutzerklärung: <a href="https://fontawesome.com/privacy" rel="noopener noreferrer nofollow" target="_blank">https://fontawesome.com/privacy</a>.
                            </li>
                            <li>
                                <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts") des Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: <a href="https://fonts.google.com/" rel="noopener noreferrer nofollow" target="_blank">https://fonts.google.com/</a>; Datenschutzerklärung: <a href="https://policies.google.com/privacy" rel="noopener noreferrer nofollow" target="_blank">https://policies.google.com/privacy</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA): <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" rel="noopener noreferrer nofollow" target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>.
                            </li>
                        </ul>
                    
                        <h2 id="m12">Löschung von Daten</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.</p>

                        <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
                        </p>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
                        </p>

                        <h2 id="m10">Rechte der betroffenen Personen</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 18 und 21 DSGVO ergeben:</p>
                        <ul className={ clsx( 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>
                                    Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                                </strong>
                            </li>
                            <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.</li>
                            <li>
                                <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                            </li>
                            <li>
                                <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                            </li>
                            <li>
                                <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
                            </li>
                            <li>
                                <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
                            </li>
                            <li>
                                <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht,  bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                            </li>
                        </ul>

                        <h2 id="m42">Begriffsdefinitionen</h2>
                        <p className={ clsx( 'uk-text-justify' ) }>
                            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
                        </p>
                        <ul className={ clsx( 'glossary', 'uk-list', 'uk-list-bullet' ) }>
                            <li>
                                <strong>Besuchsaktionsauswertung:</strong> "Besuchsaktionsauswertung" (englisch "Conversion Tracking") bezeichnet ein Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren).
                            </li>
                            <li>
                                <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse nicht mehr der eindeutigen Identifizierung einer Person dienen kann. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung von Verarbeitungsverfahren, insbesondere im Onlinemarketing
                            </li>
                            <li>
                                <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
                            </li>
                            <li>
                                <strong>Profiling:</strong> Als "Profiling“ wird jede Art der automatisierten Verarbeitung personenbezogener Daten bezeichnet, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach Art des Profilings gehören dazu Informationen betreffend das Alter, das Geschlecht, Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und deren Inhalten, Einkaufsverhalten, soziale Interaktionen mit anderen Menschen) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und Web-Beacons eingesetzt.
                            </li>
                            <li>
                                <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
                            </li>
                            <li>
                                <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“ spricht man, wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich ein Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu erinnern.
                            </li>
                            <li>
                                <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens- und Interessensinformationen in Cookies oder auf Servern der Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling). Diese Informationen können anschließend z.B. eingesetzt werden, um den Nutzern Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
                            </li>
                            <li>
                                <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
                            </li>
                            <li>
                                <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
                            </li>
                        </ul>
                    
                        <p className={ clsx( 'seal', 'uk-text-justify' ) }>
                            <a href="https://datenschutz-generator.de/?l=de" rel="noopener noreferrer nofollow" target="_blank" uk-tooltip="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken.">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a>
                        </p>
                        
                    </div>
                </div>

            </Layout>
        </>
    )

}

export default DatenschutzPage
